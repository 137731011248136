import { api } from './axiosConfig';

export const fetchPetitions = async () => {
    try {
        const response = await api.post('/fetchPetitions');

        return response.data;
    } catch (error) {
        // Handle errors that occur during the API call
        console.error('Error fetching petitions:', error.response ? error.response.data : error.message);
        throw new Error(error.response ? error.response.data.error : 'Network response was not ok');
    }
};

export const fetchPetitionsSharedWithMe = async () => {
    try {
        const response = await api.post('/fetchPetitionsSharedWithMe');

        return response.data;
    } catch (error) {
        // Handle errors that occur during the API call
        console.error('Error fetching shared petitions:', error.response ? error.response.data : error.message);
        throw new Error(error.response ? error.response.data.error : 'Network response was not ok');
    }
};

export const fetchPetition = async (petitionId) => {
    try {
        const response = await api.post('/fetchPetition', {
            petitionId
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching petition:', error.response ? error.response.data : error.message);
        throw new Error(error.response ? error.response.data.error : 'Network response was not ok');
    }
};

export const addPetition = async (formState, baseFolderId, oauthAccessToken) => {
    try {
        const response = await api.post('/addPetition', {
            formState, baseFolderId, oauthAccessToken
        });
        return response.data;
    } catch (error) {
        console.error('Error adding petition:', error.response ? error.response.data : error.message);
        if (error.response && error.response.status === 401) {
            throw new Error('Unauthorized');
        } else {
            throw new Error('Network response was not ok');
        }
    }
};

export const deletePetition = async (petitionId) => {
    try {
        const response = await api.post('/deletePetition', {
            petitionId
        });
        return response.data;
    } catch (error) {
        console.error('Error deleting petition:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const updateDocument = async (docPath, updateData) => {
    try {
        const response = await api.post('/updateDocument', {
            docPath, updateData
        });
        return response.data;
    } catch (error) {
        console.error('Error updating document:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const fetchExhibits = async (petitionId, type) => {
    try {
        const response = await api.post('/fetchExhibits', { petitionId, type });
        return response.data;
    } catch (error) {
        console.error('Error fetching exhibits:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const fetchExhibit = async (petitionId, exhibitId, type) => {
    try {
        const response = await api.post('/fetchExhibit', { petitionId, exhibitId, type });
        return response.data;
    } catch (error) {
        console.error('Error fetching exhibit:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const addExhibit = async (formState, petitionId, oauthAccessToken, petitionFolderId, type) => {
    try {
        const response = await api.post('/addExhibit', { formState, petitionId, oauthAccessToken, petitionFolderId, type });
        return response.data;
    } catch (error) {
        console.error('Error adding exhibit:', error.response ? error.response.data : error.message);
        if (error.response && error.response.status === 401) {
            throw new Error('Unauthorized');
        } else {
            throw new Error('Network response was not ok');
        }
    }
};

export const deleteExhibit = async (petitionId, exhibitId, type) => {
    try {
        const response = await api.post('/deleteExhibit', { petitionId, exhibitId, type });
        return response.data;
    } catch (error) {
        console.error('Error deleting exhibit:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const renameExhibit = async (petitionId, exhibitId, newname, type) => {
    try {
        const response = await api.post('/renameExhibit', { petitionId, exhibitId, newname, type });
        return response.data;
    } catch (error) {
        console.error('Error deleting exhibit:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const fetchFiles = async (petitionId, exhibitId, type) => {
    try {
        const response = await api.post('/fetchFiles', { petitionId, exhibitId, type });
        return response.data;
    } catch (error) {
        console.error('Error fetching files:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const deleteFile = async (petitionId, exhibitId, fileId, type) => {
    try {
        const response = await api.post('/deleteFile', { petitionId, exhibitId, fileId, type });
        return response.data;
    } catch (error) {
        console.error('Error deleting file:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const addFile = async (file, petitionId, exhibitId, exhibitFolderId, oauthAccessToken, type) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);
    formData.append('metadata', JSON.stringify({ name: file.name, mimeType: file.type, parents: [exhibitFolderId] }));
    formData.append('petitionId', petitionId);
    formData.append('exhibitId', exhibitId);
    formData.append('oauthAccessToken', oauthAccessToken);

    try {
        const response = await api.post('/addFile', formData); // Axios automatically sets the correct content type for FormData
        return response.data;
    } catch (error) {
        console.error('Error adding file:', error.response ? error.response.data : error.message);
        if (error.response && error.response.status === 401) {
            throw new Error('Unauthorized');
        } else {
            throw new Error('Network response was not ok');
        }
    }
};

export const addFileRFE = async (file, petitionId, petitionFolderId, oauthAccessToken) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('metadata', JSON.stringify({ name: file.name, mimeType: file.type, parents: [petitionFolderId] }));
    formData.append('petitionId', petitionId);
    formData.append('oauthAccessToken', oauthAccessToken);

    try {
        const response = await api.post('/addFileRFE', formData); // Axios automatically sets the correct content type for FormData
        return response.data;
    } catch (error) {
        console.error('Error adding file:', error.response ? error.response.data : error.message);

        if (error.response && error.response.status === 401) {
            throw new Error('Unauthorized');
        } else if (error.response && error.response.status === 413) {
            throw new Error('Too Large');
        } else if (error.message === 'Network Error') {
            // Check for 413 error in the response headers
            const contentLengthHeader = error.response?.headers?.['content-length'];
            if (contentLengthHeader && contentLengthHeader === '0') {
                throw new Error('Too Large');
            } else {
                throw new Error('Network Error');
            }
        } else {
            throw new Error('An error occurred');
        }
    }
};

export const addFileDocs = async (fileName, petitionId, exhibitId, exhibitFolderId, oauthAccessToken, type) => {
    try {
        const response = await api.post('/addFileDocs', { fileName, petitionId, exhibitId, exhibitFolderId, oauthAccessToken, type });
        return response.data;
    } catch (error) {
        console.error('Error adding file docs:', error.response ? error.response.data : error.message);
        if (error.response && error.response.status === 401) {
            throw new Error('Unauthorized');
        } else {
            throw new Error('Network response was not ok');
        }
    }
};

export const calculateExhibitDistribution = async (petitionId, type) => {
    try {
        const response = await api.post('/calculateExhibitDistribution', { petitionId, type });
        return response.data;
    } catch (error) {
        console.error('Error calculating exhibit distribution:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const getPetitionStatuses = async (receiptNumber) => {
    try {
        const response = await api.post('/getPetitionStatuses', { receiptNumber });
        return response.data;
    } catch (error) {
        console.error('Error getting petition statuses:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const calculateEngagementPercentile = async (petitionId) => {
    try {
        const response = await api.post('/calculateEngagementPercentile', { petitionId });
        return response.data;
    } catch (error) {
        console.error('Error calculating engagement percentile:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const fetchPetitionExhibitsAnalysis = async (petitionId, type) => {
    try {
        const response = await api.post('/fetchPetitionExhibitsAnalysis', { petitionId, type });
        return response.data;
    } catch (error) {
        console.error('Error fetching petition exhibits analysis:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const fetchForms = async (petitionId, type) => {
    try {
        const response = await api.post('/fetchForms', { petitionId, type });
        return response.data;
    } catch (error) {
        console.error('Error fetching forms:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const deleteForm = async (petitionId, formId, type) => {
    try {
        const response = await api.post('/deleteForm', { petitionId, formId, type });
        return response.data;
    } catch (error) {
        console.error('Error deleting form:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const addForm = async (file, petitionId, formType, petitionFolderId, oauthAccessToken, type) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('metadata', JSON.stringify({ name: file.name, mimeType: file.type, parents: [petitionFolderId] }));
    formData.append('petitionId', petitionId);
    formData.append('formType', formType);
    formData.append('oauthAccessToken', oauthAccessToken);
    formData.append('type', type);

    try {
        const response = await api.post('/addForm', formData); // Axios automatically sets the correct content type for FormData
        return response.data;
    } catch (error) {
        console.error('Error adding file:', error.response ? error.response.data : error.message);
        if (error.response && error.response.status === 401) {
            throw new Error('Unauthorized');
        } else {
            throw new Error('Network response was not ok');
        }
    }
};

export const fetchCoverLetter = async (petitionId, type) => {
    try {
        const response = await api.post('/fetchCoverLetter', { petitionId, type });
        return response.data;
    } catch (error) {
        console.error('Error fetching cover letter:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const createCoverLetter = async (petitionId, petitionFolderId, oauthAccessToken, type) => {
    try {
        const response = await api.post('/createCoverLetter', { petitionId, petitionFolderId, oauthAccessToken, type });
        return response.data;
    } catch (error) {
        console.error('Error creating cover letter :', error.response ? error.response.data : error.message);

        if (error.response && error.response.status === 401) {
            throw new Error('Unauthorized');
        } else {
            throw new Error('Network response was not ok');
        }
    }
};

export const getCoverLetterWordCloud = async (googleDocId, oauthAccessToken) => {
    try {
        const response = await api.post('/getCoverLetterWordCloud', { googleDocId, oauthAccessToken });
        return response.data;
    } catch (error) {
        console.error('Error fetching exhibits:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const fetchPlan = async (petitionId, type) => {
    try {
        const response = await api.post('/fetchPlan', { petitionId, type });
        return response.data;
    } catch (error) {
        console.error('Error fetching plan:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const createPlan = async (petitionId, petitionFolderId, oauthAccessToken, type) => {
    try {
        const response = await api.post('/createPlan', { petitionId, petitionFolderId, oauthAccessToken, type });
        return response.data;
    } catch (error) {
        console.error('Error creating plan :', error.response ? error.response.data : error.message);
        if (error.response && error.response.status === 401) {
            throw new Error('Unauthorized');
        } else {
            throw new Error('Network response was not ok');
        }
    }
};

export const fetchLog = async (petitionId) => {
    try {
        const response = await api.post('/fetchLog', { petitionId });
        return response.data;
    } catch (error) {
        console.error('Error fetching log:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const fetchCaseTimeline = async (receiptNumber) => {
    try {
        const response = await api.post('/fetchCaseTimeline', { receiptNumber });
        return response.data;
    } catch (error) {
        console.error('Error fetching log:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const addReceiptNumber = async (receiptNumber, petitionId) => {
    try {
        const response = await api.post('/addReceiptNumber', { receiptNumber, petitionId });
        return response.data;
    } catch (error) {
        console.error('Error saving Receipt Number :', error.response ? error.response.data : error.message);
        if (error.response && error.response.status === 401) {
            throw new Error('Unauthorized');
        } else {
            throw new Error('Network response was not ok');
        }
    }
};

export const fetchPetitionTimeline = async (petitionId) => {
    try {
        const response = await api.post('/fetchPetitionTimeline', { petitionId });
        return response.data;
    } catch (error) {
        console.error('Error fetching log:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const updateExhibitFilesInfo = async (petitionId, exhibitId, oauthAccessToken, type) => {
    try {
        const response = await api.post('/updateExhibitFilesInfo', { petitionId, exhibitId, oauthAccessToken, type });
        return response.data;
    } catch (error) {
        console.error('Error updating exhibit:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const getCoverLetterAnalysis = async (petitionId, type) => {
    try {
        const response = await api.post('/getCoverLetterAnalysis', { petitionId, type });
        return response.data;
    } catch (error) {
        console.error('Error fetching cover letter analysis:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const fetchRFE = async (petitionId) => {
    try {
        const response = await api.post('/fetchRFE', { petitionId });
        return response.data;
    } catch (error) {
        console.error('Error fetching RFE:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const fetchRFESentences = async (docId) => {
    try {
        const response = await api.post('/fetchRFESentences', { docId });
        return response.data;
    } catch (error) {
        console.error('Error fetching RFE sentences:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const updateExhibitPosition = async (petitionId, exhibitId, type, currentPosition, newPosition) => {
    try {
        const response = await api.post('/updateExhibitPosition', { petitionId, exhibitId, type, currentPosition, newPosition });
        return response.data;
    } catch (error) {
        console.error('Error adding exhibit:', error.response ? error.response.data : error.message);
        if (error.response && error.response.status === 401) {
            throw new Error('Unauthorized');
        } else {
            throw new Error('Network response was not ok');
        }
    }
};


export const updateExhibitFileVisibility = async (petitionId, exhibitId, type, fileId, isHidden) => {
    try {
        const response = await api.post('/updateExhibitFileVisibility', { petitionId, exhibitId, type, fileId, isHidden });
        return response.data;
    } catch (error) {
        console.error('Error calculating exhibit distribution:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const fetchCasesHistogram = async () => {
    try {
        const response = await api.post('/fetchCasesHistogram', {});
        return response.data;
    } catch (error) {
        console.error('Error fetching Histogram:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const fetchLastDaysData = async () => {
    try {
        const response = await api.post('/fetchLastDaysData', {});
        return response.data;
    } catch (error) {
        console.error('Error fetching Last days data:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const addFileCoverLetter = async (file, petitionId, petitionFolderId, type, oauthAccessToken) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('metadata', JSON.stringify({ name: file.name, mimeType: file.type, parents: [petitionFolderId] }));
    formData.append('petitionId', petitionId);
    formData.append('type', type);
    formData.append('oauthAccessToken', oauthAccessToken);

    try {
        const response = await api.post('/addFileCoverLetter', formData); // Axios automatically sets the correct content type for FormData
        return response.data;
    } catch (error) {
        console.error('Error adding file:', error.response ? error.response.data : error.message);

        if (error.response && error.response.status === 401) {
            throw new Error('Unauthorized');
        } else if (error.response && error.response.status === 413) {
            throw new Error('Too Large');
        } else if (error.message === 'Network Error') {
            // Check for 413 error in the response headers
            const contentLengthHeader = error.response?.headers?.['content-length'];
            if (contentLengthHeader && contentLengthHeader === '0') {
                throw new Error('Too Large');
            } else {
                throw new Error('Network Error');
            }
        } else {
            throw new Error('An error occurred');
        }
    }
};

export const addFilePlan = async (file, petitionId, petitionFolderId, type, oauthAccessToken) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('metadata', JSON.stringify({ name: file.name, mimeType: file.type, parents: [petitionFolderId] }));
    formData.append('petitionId', petitionId);
    formData.append('type', type);
    formData.append('oauthAccessToken', oauthAccessToken);

    try {
        const response = await api.post('/addFilePlan', formData); // Axios automatically sets the correct content type for FormData
        return response.data;
    } catch (error) {
        console.error('Error adding file:', error.response ? error.response.data : error.message);

        if (error.response && error.response.status === 401) {
            throw new Error('Unauthorized');
        } else if (error.response && error.response.status === 413) {
            throw new Error('Too Large');
        } else if (error.message === 'Network Error') {
            // Check for 413 error in the response headers
            const contentLengthHeader = error.response?.headers?.['content-length'];
            if (contentLengthHeader && contentLengthHeader === '0') {
                throw new Error('Too Large');
            } else {
                throw new Error('Network Error');
            }
        } else {
            throw new Error('An error occurred');
        }
    }
};

export const fetchRecentPetitions = async () => {
    try {
        const response = await api.post('/fetchRecentPetitions', {});
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const fetchRecentRFEs = async () => {
    try {
        const response = await api.post('/fetchRecentRFEs', {});
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const fetchRecentUsers = async () => {
    try {
        const response = await api.post('/fetchRecentUsers', {});
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const fetchRecentExhibits = async () => {
    try {
        const response = await api.post('/fetchRecentExhibits', {});
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const fetchRecentMovements = async () => {
    try {
        const response = await api.post('/fetchRecentMovements', {});
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const fetchRecentMovementsPerPetition = async () => {
    try {
        const response = await api.post('/fetchRecentMovementsPerPetition', {});
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const fetchTopEngagementPetitions = async () => {
    try {
        const response = await api.post('/fetchTopEngagementPetitions', {});
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const updatePetition = async (petitionId, formState) => {
    try {
        const response = await api.post('updatePetition', { petitionId, formState });
        return response.data;
    } catch (error) {
        console.error('Error updating petition:', error);
        throw error;
    }
};

export const deleteCoverLetter = async (petitionId, type) => {
    try {
        const response = await api.post('/deleteCoverLetter', { petitionId, type });
        return response.data;
    } catch (error) {
        console.error('Error deleting cover letter:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const deletePlan = async (petitionId, type) => {
    try {
        const response = await api.post('/deletePlan', { petitionId, type });
        return response.data;
    } catch (error) {
        console.error('Error deleting plan:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const fetchRecentPosts = async (category, searchTerm, hashtag, page) => {
    try {
        const response = await api.post('/fetchRecentPosts', { category, searchTerm, hashtag, page });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const fetchPost = async (postId) => {
    try {
        const response = await api.post('/fetchPost', { postId });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const addPost = async (postData) => {
    try {
        const response = await api.post('/addPost', { postData });
        return response.data;
    } catch (error) {
        console.error('Error adding post:', error.response ? error.response.data : error.message);
        throw error;
    }
}

export const addComment = async (postId, commentData) => {
    try {
        const response = await api.post('/addComment', { postId, commentData });
        return response.data;
    } catch (error) {
        console.error('Error adding post:', error.response ? error.response.data : error.message);
        throw error;
    }
}

export const fetchComments = async (postId) => {
    try {
        const response = await api.post('/fetchComments', { postId });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const saveLike = async (postId, userId, isLike) => {
    try {
        const response = await api.post('/saveLike', { postId, userId, isLike });
        return response.data;
    } catch (error) {
        console.error('Error adding post:', error.response ? error.response.data : error.message);
        throw error;
    }
}

export const saveLikeComment = async (postId, commentId, userId, isLike) => {
    try {
        const response = await api.post('/saveLikeComment', { postId, commentId, userId, isLike });
        return response.data;
    } catch (error) {
        console.error('Error adding post:', error.response ? error.response.data : error.message);
        throw error;
    }
}

// export const fetchUserLike = async (postId, userId) => {
//     try {
//         const response = await api.post('/fetchUserLike', { postId, userId });
//         return response.data;
//     } catch (error) {
//         console.error('Error fetching data:', error.response ? error.response.data : error.message);
//         throw new Error('Network response was not ok');
//     }
// };

// export const fetchUserLikeComment = async (postId, userId, commentId) => {
//     try {
//         const response = await api.post('/fetchUserLikeComment', { postId, userId, commentId });
//         return response.data;
//     } catch (error) {
//         console.error('Error fetching data:', error.response ? error.response.data : error.message);
//         throw new Error('Network response was not ok');
//     }
// };

export const getTopHashtags = async () => {
    try {
        const response = await api.post('/getTopHashtags', {});
        return response.data;
    } catch (error) {
        console.error('Error getting petition statuses:', error.response ? error.response.data : error.message);
        throw new Error('Network response was not ok');
    }
};

export const sendContactForm = async (formState, userId, userName, userEmail, userPhoto, ipAddress, currentUrl) => {
    try {
        const response = await api.post('/sendContactForm', { formState, userId, userName, userEmail, userPhoto, ipAddress, currentUrl });
        return response.data;
    } catch (error) {
        console.error('Error sending message:', error.response ? error.response.data : error.message);
        if (error.response && error.response.status === 401) {
            throw new Error('Unauthorized');
        } else {
            throw new Error('Network response was not ok');
        }
    }
};

export const fetchSharedWithUsers = async (petitionId) => {
    try {
        const response = await api.post('/fetchSharedWithUsers', {
            petitionId
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching shared users:', error.response ? error.response.data : error.message);
        throw new Error(error.response ? error.response.data.error : 'Network response was not ok');
    }
};

export const addSharedWithUser = async (petitionId, sharedUserEmail, oauthAccessToken) => {
    try {
        const response = await api.post('/addSharedWithUser', {
            petitionId,
            sharedUserEmail,
            oauthAccessToken
        });
        return response.data;
    } catch (error) {
        let message = 'Error adding shared user. <br /> ' + error.response.data
        throw message;
    }
};

export const removeSharedWithUser = async (petitionId, sharedUserId, oauthAccessToken) => {
    try {
        const response = await api.post('/removeSharedWithUser', {
            petitionId,
            sharedUserId,
            oauthAccessToken
        });
        return response.data;
    } catch (error) {
        console.error('Error removing shared user:', error.response ? error.response.data : error.message);
        throw new Error('Failed to remove shared user');
    }
};

export const setUserProfileType = async (profileType) => {
    try {
        const response = await api.post('/setUserProfileType', { profileType });
        return response.data;
    } catch (error) {
        console.error('Error removing shared user:', error.response ? error.response.data : error.message);
        throw new Error('Failed to remove shared user');
    }
};