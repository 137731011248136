import axios from 'axios';
import { signInWithPopup } from 'firebase/auth';
import { auth, provider } from './firebase-config';

const verifyTokenScopes = async (token, requiredScope) => {
    try {
        const response = await axios.get(`https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${token}`);
        const scopes = response.data.scope.split(' ');
        // console.log(scopes);
        return scopes.includes(requiredScope);
    } catch (error) {
        console.error('Error verifying token scopes:', error);
        return false;
    }
};

const signInWithGoogle = async (logout) => {
    try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        const oauthAccessToken = result._tokenResponse.oauthAccessToken;
        const oauthRefreshToken = result._tokenResponse.refreshToken;

        // Verify that the required scope is present
        const requiredScope = 'https://www.googleapis.com/auth/drive.file';
        const hasRequiredScope = await verifyTokenScopes(oauthAccessToken, requiredScope);

        if (!hasRequiredScope) {
            await logout();
            throw new Error('Required permission not granted.');
        }

        const userData = {
            Name: user.displayName || 'No Name Provided',
            Email: user.email,
            Photo: user.photoURL || 'No Photo Provided',
            ProviderId: user.providerData[0].providerId,
            uid: user.uid
        };

        // Send data to your backend
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/createUser`, {
            uid: user.uid,
            userData,
            oauthAccessToken,
            oauthRefreshToken
        });

        if (response.data.success) {
            userData.BaseFolderId = response.data.BaseFolderId;
            return { success: true, user: userData, oauthAccessToken };
        } else {
            throw new Error('Failed to create or update user in the backend.');
        }
    } catch (error) {
        console.error("Error during Google sign in:", error);
        await logout();
        throw error;  // Rethrow to handle it in the calling context
    }
};

export { signInWithGoogle };
